import 'custom-event-polyfill';

// Click Events
export const LANDER_CLICK = 'LanderClick';
export const FIRST_CLICK = 'FirstClick';
export const SECOND_CLICK = 'SecondClick';
export const CONTENT_CLICK = 'ContentClick';
export const CONTENT_BLOCKED = 'ContentBlocked';
export const NOT_CONTENT_BLOCKED = 'NotContentBlocked';
export const FORBIDDEN = 'Forbidden';
export const CONTINUE_CLICK = 'ContinueClick';
export const OPTIN_CHECKED = 'OptinChecked';
export const OPTIN_UNCHECKED = 'OptinUnchecked';
export const OPTIN_CONFIRM = 'OptinConfirm';
export const TERMS_CHECKED = 'TermsChecked';
export const TERMS_UNCHECKED = 'TermsUnchecked';
export const EXIT_CLICK = 'ExitClick';
export const WIFI_CLICK = 'WifiClick';
export const OPEN_EXTERNAL_CLICK = 'OpenExternalClick';
export const PAY_BY_CARD_CLICK = 'PayByCardClick';
export const PAGE_TIMEOUT = 'PageTimeout';
export const SLIDER_TRIGGER = 'SliderTrigger';
export const TRY_FOR_FREE = 'TryForFree';
export const CANCEL_CLICK = 'CancelClick';
export const OTP_CHALLENGE = 'OtpChallenge';
export const CONSENT_TICKBOX_CHANGED = 'ConsentTickboxChanged';
export const CTA_SHOWN='CTAShown';
export const USERNAME_SUBMIT='UsernameSubmit';

export const USERNAME_CHANGE='UsernameChange';
export const USERNAME_SUBMIT_ERROR='UsernameSubmitError';

// Subscription events
export const SUBSCRIPTION_FAILED = 'SubscriptionFailed';
export const SUBSCRIPTION_SUCCEEDED = 'SubscriptionSucceeded';
export const SUBSCRIPTION_CANCELLED = 'SubscriptionCancelled';
export const SUBSCRIPTION_EXISTS = 'SubscriptionExists';
export const SUBSCRIPTION_NOT_FOUND = 'SubscriptionNotFound';
export const CHECKING_SUBSCRIPTION = 'CheckingSubscription';
export const PRE_SUBSCRIBE = 'PreSubscribe';
export const SUBSCRIBE = 'Subscribe';
export const VERIFYING_SUBSCRIPTION = 'VerifyingSubscription';
export const VERIFY_SUBSCRIPTION = 'VerifySubscription';

// Identification events
export const IDENTIFY_CALLED = 'IdentifyCalled';
export const IDENTIFY_SUCCESS = 'IdentifySuccess';
export const IDENTIFY_FAILURE = 'IdentifyFailure';
export const IDENTIFY_ERROR = 'IdentifyError';
export const ACCESS_DENIED = 'AccessDenied';
export const ACCESS_GRANTED = 'AccessGranted';
export const UNIDENTIFIED_USER = 'UnidentifiedUser';

// Integrator events
export const INTEGRATOR_ERROR = 'IntegratorError';
export const INTEGRATOR_CALL_SUCCEEDED = 'IntegratorCallSucceeded';
export const INTEGRATOR_INIT_SUCCEEDED = 'IntegratorInitSucceeded';
export const INTEGRATOR_LOAD_ERROR = 'IntegratorLoadError';

// Stripe events
export const STRIPE_LOAD_ERROR = 'StripeLoadError';
export const STRIPE_REDIRECTING = 'StripeRedirecting';

// Flow events
export const FRAUD_DETECTED = 'FraudDetected';
export const SHOWN_LANDING_PAGE = 'ShownLandingPage';
export const SHOWN_LANDER = 'ShownLander';
export const SHOW_WIFI = 'ShowWifi';
export const LANGUAGE_SWITCHED = 'LanguageSwitched';
export const PAGE_RELOAD = 'ReloadPage';
export const REDIRECT_TO_PRODUCT = 'RedirectToProduct';
export const REDIRECT_TO_LANDING_PAGE = 'RedirectToLandingPage';
export const REDIRECTING = 'Redirecting';
export const SHOWN_SUBSCRIBE_BUTTON = 'ShownSubscribeButton';
export const FLOW_EXIT = 'FlowExit';
export const IFRAME_LOADED = 'IframeLoaded';
export const FORCE_HTTP = 'ForceHttp';
export const FORCE_HTTPS = 'ForceHttps';
export const NAVIGATION_TIMING = 'NavigationTiming';
export const PERFORMANCE_TRACKING = 'PerformanceTracking';
// OTP events
export const OTP_CHALLENGE_SHOWN = 'OtpChallengeShown';
export const OTP_CHALLENGE_SUBMIT = 'OtpChallengeSubmit';
export const OTP_PIN_VALIDATE_SHOWN = 'OtpChallengeShown';
export const OTP_CORRECT_PIN = 'OtpCorrectPin';
export const OTP_PIN_EXPIRED = 'OtpPinExpired';
export const OTP_INCORRECT_PIN = 'OtpIncorrectPin';
export const OTP_GENERATE_SUCCESS = 'OtpGenerateSuccess';
export const OTP_GENERATE_FAILURE = 'OtpGenerateFailure';
export const OTP_CHALLENGE_CONFIRM = 'OtpChallengeConfirm';
export const OTP_CHALLENGE_VALIDATE = 'OtpChallengeValidate';
export const OTP_VALIDATE_SUCCESS = 'OtpValidateSuccess';

export const HE_OTP_CHALLENGE_SUBMIT = 'HeOtpChallengeSubmit'

// PIN events
export const PIN_GENERATE_SUCCESS = 'PinGenerateSuccess';
export const PIN_GENERATE_FAILURE = 'PinGenerateFailure';
export const PIN_CHALLENGE_VALIDATE = 'PinChallengeValidate';

// Plan selection events
export const PLAN_CHOSEN = 'PlanChosen';

// App lifecycle events
export const APP_INIT = 'AppInit';
export const APP_POST_INIT = 'AppPostInit';

export const NOTIFICATION_ERROR = 'NotificationError';

// Universal Login events
export const UNIVERSAL_LOGIN_LOGIN_BUTTON_CLICKED = 'UniversalLoginLoginButtonClicked';
export const UNIVERSAL_LOGIN_SIGNUP_BUTTON_CLICKED = 'UniversalLoginSignupButtonClicked';
