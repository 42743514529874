import Tracker from './modules/tracker';
import logger from './modules/logger';
import * as events from './modules/events';
import Dispatcher from './modules/dispatcher';
import UIControl from './modules/uicontrol';
import Flow from './modules/flow/flow';
import Integrator from './modules/integrator';

const LandingPage = {
    // initialisation data
    data: {
        translations: {},
        metadata: {},
        integratorBaseUrl: '',
        integratorIdentity: '',
        flowModule: '',
        strategy: '',
        plan: '',
        territory: '',
        productUrl: '',
        productSlug: '',
        locale: 'xx',
        request_id: '',
        identity: '',
        isKnown: null,
        fraudDetection: '',
        trackConversions: false,
        environment: 'prod',
        integratorResponseCode: 200,
        integratorSureOnSubscribe: false,
        integratorIdentifyUrl: '',
        internalCall: false,
        landingPageSubscribe: false,
        suggestedUsername: ''
    },

    // modules
    logger,
    events,
    integrator: {},
    tracker: {},
    dispatcher: {},
    uiControl: {},
    flow: {},

    /**
     * Initialise the LP object with all the modules needed
     * @param {object} options
     */
    init(options) {
        if (options && typeof options === 'object') {
            const props = Object.keys(options);
            for (let i = 0; i < props.length; i += 1) {
                const key = props[i];
                if (Object.prototype.hasOwnProperty.call(options, key)
                    && Object.prototype.hasOwnProperty.call(this.data, key)) {
                    this.data[key] = options[key];
                }
            }
        }

        this.logger.setLevel("info");
        let context = {};
        if(typeof window.DD_LOGS !== 'undefined' && typeof window.DD_LOGS.getGlobalContext === 'function'){
            context = typeof window.DD_LOGS.getGlobalContext() === 'undefined' ? {} : window.DD_LOGS.getGlobalContext()
        }
        this.logger.setGlobalContext({...context, "event_tracking_id": this.data.metadata.event_tracking_id})

        // Initialise modules
        this.dispatcher = new Dispatcher(this.logger);
        this.tracker = new Tracker(
            this.logger,
            {
                pageRequestId: this.data.request_id,
                data: {
                    locale: this.data.locale,
                    identity: this.data.identity,
                    identified: this.data.isKnown,
                },
            },
            this.data.metadata.event_tracking_id,
        );
        this.uiControl = new UIControl(this.data.translations, this.dispatcher, this.logger);

        // set up integrator with default/base data
        this.integrator = new Integrator(
            this.data.integratorBaseUrl,
            {
                plan: this.data.plan,
                locale: this.data.locale,
                product: this.data.productSlug,
            },
            this.logger,
            this.data.landingPageSubscribe == "1" ? true: false
        );


        // set up flow
        this.flow = new Flow(this.data.flowModule, this.logger);
        this.flow.init({
            dispatcher: this.dispatcher,
            tracker: this.tracker,
            uiControl: this.uiControl,
            logger: this.logger,
            integrator: this.integrator,
            productUrl: this.data.productUrl,
            integratorIdentity: this.data.integratorIdentity,
            productSlug: this.data.productSlug,
            locale: this.data.locale,
            identity: this.data.identity,
            plan: this.data.plan,
            territory: this.data.territory,
            trackConversions: this.data.trackConversions,
            internalCall: this.data.internalCall,
            fraudDetection: this.data.fraudDetection,
            integratorResponseCode: this.data.integratorResponseCode,
            integratorIdentifyUrl: this.data.integratorIdentifyUrl,
            metadata: this.data.metadata,
            suggestedUsername: this.data.suggestedUsername,
        });

        // Are you sure message when user navigates from page when already clicked subscribe.
        if (this.data.integratorSureOnSubscribe) {
            this.dispatcher.addEventListener(events.PRE_SUBSCRIBE, () => {
                // Enable navigation prompt
                window.onbeforeunload = function sureOnSub() {
                    return true;
                };
            });
            const subCompleteEvents = [
                events.SUBSCRIPTION_FAILED,
                events.SUBSCRIPTION_SUCCEEDED,
                events.SUBSCRIPTION_CANCELLED,
                events.SUBSCRIPTION_EXISTS,
                events.SUBSCRIPTION_NOT_FOUND,
                events.IDENTIFY_FAILURE,
                events.IDENTIFY_ERROR,
                events.FRAUD_DETECTED,
            ];
            subCompleteEvents.forEach((ev) => {
                this.dispatcher.addEventListener(ev, () => {
                    window.onbeforeunload = null;
                });
            });
        }


        // Track ShownLandingPage Event
        this.tracker.track(this.events.SHOWN_LANDING_PAGE);

        this.logger.debug('LandingPage: finished initialising');
        this.dispatcher.dispatchEvent(events.APP_POST_INIT);

        // Track FlowExit Event
        const flowExitHandler = () => {
            this.dispatcher.dispatchEvent(events.FLOW_EXIT);
        };
        // Track users performance of the landing page
        window.addEventListener('beforeunload', flowExitHandler.bind(this));
        window.addEventListener('unload', flowExitHandler.bind(this));
    },
    /**
     * Add metadata to the LP object
     * @param {object} meta
     */
    addMetadata(meta) {
        this.data.metadata = Object.assign(this.data.metadata, meta);
    },

    /**
     * Initialise fraud detection scripts
     */
    bootstrapFraudDetection(provider) {
        this.logger.debug('LandingPage: bootstrapping FraudDetection', provider);
        this.data.fraudDetection = provider;
    },

};

export default LandingPage;

window.LandingPage = LandingPage;
