import { SECOND_CLICK, OTP_VALIDATE_SUCCESS, FIRST_CLICK, OTP_GENERATE_SUCCESS} from "./events";

class Tracker {
    /**
     * Constructor
     * @param {Logger} logger
     * @param {*} defaultPayload
     * @param {string} eventTrackingId
     */
    constructor(logger, defaultPayload = {}, eventTrackingId) {
        this.logger = logger;
        this.defaultPayload = defaultPayload;
        this.eventTrackingId = eventTrackingId;
    }

    /* eslint-disable class-methods-use-this */

    /**
     * Deep copies data to event Object. Returns new event Object.
     * @param event Object
     * @param data Object
     * @returns {Object}
     */
    deepUpdateEvent(event, data) {
        const ev = event;
        // assign data by deep copying the object
        if (data && typeof data === 'object') {
            const props = Object.keys(data);
            for (let i = 0; i < props.length; i += 1) {
                const key = props[i];
                if (Object.hasOwnProperty.call(event, key) && typeof event[key] === 'object') {
                    ev[key] = Object.assign(event[key], data[key]);
                } else {
                    ev[key] = data[key];
                }
            }
        }
        return ev;
    }

    /* eslint-enable class-methods-use-this */

    /**
     * Add default payload for all future events
     * @param {*} load
     */
    appendDefaultPayload(load) {
        this.defaultPayload = Object.assign(this.defaultPayload, load);
    }

    flattenObject(ob) {
        if (typeof ob !== 'object') {
            return ob
        }
        let result = {};
        for (const i in ob) {
            if ((typeof ob[i]) === 'object' && !Array.isArray(ob[i])) {
                const temp = this.flattenObject(ob[i]);
                for (const j in temp) {
                    result[i + '_' + j] = temp[j];
                }
            }
            else {
                result[i] = ob[i];
            }
        }
        return result;
    }


    setupDefaultGAConsent(){
        if(typeof gtag === 'function'){
            gtag('consent', 'default', {
                'ad_storage': 'denied',
                'ad_user_data': 'denied',
                'ad_personalization': 'denied',
                'analytics_storage': 'denied'
            });
        }else{
            this.logger.warn('GTAG Function could not be found')
        }
    }

    handleGAConsent(granted = false){
        if(typeof gtag === 'function'){
            if(granted){
                gtag('consent', 'update', {
                    'ad_user_data': 'granted',
                    'ad_personalization': 'granted',
                    'ad_storage': 'granted',
                    'analytics_storage': 'granted'
                  });
            }else{
                gtag('consent', 'update', {
                    'ad_user_data': 'denied',
                    'ad_personalization': 'denied',
                    'ad_storage': 'denied',
                    'analytics_storage': 'denied'
                  });
            }
            
        }else{
            this.logger.warn('GTAG Function could not be found')
        }
    }

    trackGAEvent(key, payload) {
        const acceptableEvents = ['IdentifySuccess', 'FirstClick', 'SecondClick', 'SubscriptionSucceeded', 'IdentifyFailure', 'SubscriptionExists', 'IdentifyError', 'FraudDetected', 'ContentBlocked']
        if (typeof gtag === 'function' && acceptableEvents.includes(key)) {
            //add metadata to the payload
            let pageMetaData = {};
            try {
                pageMetaData = window.LandingPage.flow.flow.metadata
            } catch (e) {
                this.logger.warn(`Metadata not found. Error: ${JSON.stringify(e)}`)
            }
            payload = this.flattenObject({...payload, pageMetaData})
            //created in _google.html.twig
            gtag('event', key, payload)
        }
    }

    handleOptInEventsPayload(message, payload){
        //any data defined in the flow will override this data
        const metadata = window.LandingPage.flow.flow.metadata
        const plan = window.LandingPage.flow.flow.plan
        let eventTrackerType = '';
        if(message === SECOND_CLICK || message === OTP_VALIDATE_SUCCESS){
            eventTrackerType = 'OptIn'
        }else if(message === FIRST_CLICK || message === OTP_GENERATE_SUCCESS){
            eventTrackerType = 'FirstClickOptIn'
        }else{
            return payload;//if not an event that I need to modify return the payload to avoid editing all events
        }
        const newPayload = {
            data: {
                eventTrackerType: eventTrackerType,
                plan : plan,
                metadata: metadata,
            }
        }
        if(typeof payload !== 'undefined' && typeof payload.data !== 'undefined'){
            newPayload.data = {...newPayload.data, ...payload.data }
        }
        return newPayload
    }

    /**
     * Track an event
     * @param {string} message
     * @param {Object} payload
     */
    track(message, payload) {

        payload = this.handleOptInEventsPayload(message, payload)

        let event = {
            name: message,
            eventId: new Date().getTime(),
        };

        // creates default payload deep copy without reference to original object
        const defaultPayload = JSON.parse(JSON.stringify(this.defaultPayload));
        // assign default payload to created event.
        event = Object.assign(event, defaultPayload);
        const d = new Date().toLocaleString('en-UK');

        let data = {
            orientation: window.innerHeight > window.innerWidth ? 'Portrait' : 'Landscape',
            screenWidth: window.innerWidth,
            screenHeight: window.innerHeight,
            user_time: d,
        };
        if(typeof payload === 'object'){
            const payloadActual = typeof payload.data !== 'undefined' ? payload.data : payload
            Object.keys(payloadActual).forEach(key => {
                data[key] = payloadActual[key]
            })
            this.trackGAEvent(message, payloadActual)
        }

        event = this.deepUpdateEvent(event, {
            data: data,
        });

        

        const trackingUrl = new URL(window.location.pathname + '/tracking', window.location.origin);
        trackingUrl.searchParams.append('event_tracking_id', this.eventTrackingId);

        // Try to send the tracking event via beacon
        if (typeof navigator.sendBeacon === 'function') {
            event.beacon = true;

            this.logger.debug('Tracker: sending event as beacon', event, payload);
            if (navigator.sendBeacon(trackingUrl.href, JSON.stringify(event))) {
                this.logger.debug('Tracker: event sent as beacon', event, payload);
                return;
            }
            this.logger.error(`Tracker: Error sending event as beacon: ${JSON.stringify(event)}`);
        }

        event.beacon = false;
        this.logger.debug('Tracker: sending event as XHR: ', event, payload);
        const http = new XMLHttpRequest();
        http.open('POST', trackingUrl.href);
        http.setRequestHeader('Content-Type', 'application/json');
        http.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
        http.onerror = function handleError() {
            this.logger.error(`Tracker: got ERROR sending event via XHR: ${JSON.stringify(event)}`);
        };
        http.ontimeout = function handleTimeout() {
            this.logger.error(`Tracker: got TIMEOUT sending event via XHR ${JSON.stringify(event)}`);
        };

        http.onreadystatechange = () => {
            if (
                http.readyState === XMLHttpRequest.DONE
                && http.status !== 200
                && http.status !== 204
            ) {
                this.logger.error(`Unable to track event, status:${http.status}, response:${http.responseText} - ${JSON.stringify(event)}`);
            }
        };
        http.send(JSON.stringify(event));
    }

    /**
     * Perform a conversion call to the backend
     * @param {Object} data
     */
    conversion(data) {
        const basePath = window.location.pathname;
        const xhr = new XMLHttpRequest();
        const conversionData = JSON.stringify(Object.assign(data, this.defaultPayload));
        this.logger.debug('Tracker: sending conversion', conversionData);

        xhr.open('POST', `${basePath}/conversion`);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
        xhr.onreadystatechange = () => {
            if (xhr.readyState === 4) {
                if (xhr.status === 200) {
                    const range = document.createRange();
                    range.selectNode(document.getElementsByTagName('body')[0]);
                    const documentFragment = range.createContextualFragment(xhr.responseText);
                    document.body.appendChild(documentFragment);
                } else {
                    this.logger.error(`Tracker: got non-200 response calling conversion endpoint ${xhr.status} ${conversionData}`);
                }
            }
        };
        xhr.onerror = () => {
            this.logger.error(`Tracker: got ERROR calling conversion endpoint ${conversionData}`);
        };
        xhr.ontimeout = () => {
            this.logger.error(`Tracker: got TIMEOUT calling conversion endpoint ${conversionData}`);
        };

        xhr.send(conversionData);
    }
}

export default Tracker;
